import { find, includes, isNull, remove, replace } from 'lodash'
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Form, Button, Modal } from 'semantic-ui-react'
import { withFormik, FormikProps } from 'formik'
import { navigate } from 'gatsby'
import queryString from 'query-string'

import RadioButton from './upgrade-plan/RadioButton'
import PromoInput from './upgrade-plan/PromoInput'
import { Icon, SquareCloseButton } from 'src/components/shared'

import * as FormStyles from 'src/styles/upgrade-plan.module.scss'

import { IState } from '@pvolve/sdk/src/redux/selectors'
import Selectors from '@pvolve/sdk/src/app/selectors'
import SubscriptionSelector from '@pvolve/sdk/src/app/modules/subscriptions/selectors'
import Actions from 'src/state/root-actions'
import { Discount } from '@pvolve/sdk/src/CommerceService'
import { Product } from '@pvolve/sdk/src/SubscriptionService'
import ProductGroup, { ProductOffer } from '@pvolve/sdk/src/app/modules/subscriptions/ProductGroup'
import Layout from 'src/components/layout/Layout'

import Pvolve from '@pvolve/sdk'

const connector = connect((state: IState) => ({
    webManagementProducts: Selectors.subscriptions.webManagementProducts(state),
    lastInvoice: Selectors.subscriptions.lastInvoice(state),
    currentProduct: Selectors.subscriptions.currentSubscriptionProduct(state),
    loading: Selectors.subscriptions.loading(state),
    saving: Selectors.subscriptions.updatingPlan(state),
    defaultPromo: Selectors.subscriptions.defaultPromo(state),
    defaultPromoDiscount: Selectors.subscriptions.defaultPromoDiscount(state),
    subscriptionStatus: SubscriptionSelector.subscriptionStatus(state),
}))

const discountApplies = (discount: Discount, product: Product) => {
    if (discount) {
        const meetSkuRestrictions =
            isNull(discount.entitled_skus) ||
            (Array.isArray(discount.entitled_skus) && discount.entitled_skus.includes(product.sku))
        const meetMinAmountRestrictions = product?.cost?.unit_amount >= discount?.minimum_amount

        return meetSkuRestrictions && meetMinAmountRestrictions
    }

    return false
}

export interface FormValues {
    promoCode: string
    validatedPromoCode?: string
    selectedPlan: string
}

const UpgradePlanForm = ({
    handleChange,
    handleSubmit,
    setFieldValue,
    setStatus,
    values,
    status,
    webManagementProducts,
    currentProduct,
    lastInvoice,
    saving,
    loading,
    defaultPromo,
    defaultPromoDiscount,
    location,
    subscriptionStatus,
}: FormikProps<FormValues> & ConnectedProps<typeof connector>) => {
    const dispatch = useDispatch()
    const [discount, setDiscount] = useState<Discount>()
    const [renderDisclaimer, setRenderDisclaimer] = useState(false)
    const [isInvalidCode, setInvalidCode] = useState<boolean>(false)
    const [currentOffer, setCurrentOffer] = useState(null)
    let productGroup = useMemo(() => new ProductGroup(webManagementProducts, discount), [
        discount,
        webManagementProducts,
    ])

    const subscriptionsNoAvailable = ['3month_trial0', '6month_trial0']

    useEffect(() => {
        // Segment Track event on Update page Viewed
        dispatch(Actions.segment.track.appSubscriptionUpdatePageViewed())
    }, [dispatch])

    useEffect(() => {
        if (renderDisclaimer) {
            // Segment Track event on Modal Viewed
            dispatch(Actions.segment.track.appSubscriptionUpdateConfirmationModalViewed())
        }
    }, [dispatch, renderDisclaimer])

    useEffect(() => {
        if (defaultPromoDiscount) {
            setFieldValue('validatedPromoCode', defaultPromoDiscount.code)
            setDiscount(defaultPromoDiscount)
        }

        const query = queryString.parse(location.search)

        if (query.promo) {
            const { promo } = query
            values.promoCode = promo
            validateCode()
        }
    }, [defaultPromo, defaultPromoDiscount, setDiscount, setFieldValue])

    useEffect(() => {
        setFieldValue('discount', discount)
    }, [setFieldValue, discount])

    useEffect(() => {
        !!productGroup && findCurrentProduct()
    }, [currentProduct, productGroup])

    const matchingPlan = productGroup.offers?.map(planData => {
        const lastInvoiceAmount = lastInvoice?.amount && (lastInvoice.amount / 100).toFixed(2)
        const periodEnd = new Date(lastInvoice?.period_end).toLocaleDateString()
        const price = lastInvoiceAmount || planData.product.cost.currency_amount
        const interval = planData.product.subscription_details.interval
        const interval_type = planData.product.subscription_details.interval_type
        const displayCurrentPrice = () => {
            if (interval > 1) {
                return `${price} / ${interval} ${interval_type}s`
            }

            return `${price} / ${interval} ${interval_type}`
        }

        if (currentProduct?.sku === planData.product.sku) {
            const intvl = planData.product.subscription_details.interval
            const intvlType = planData.product.subscription_details.interval_type
            const isAnnual = (intvl === 1 && intvlType === 'year') || (intvl === 12 && intvlType === 'month')
            return (
                <>
                    <div>
                        <p className="p2 margin-top--large">
                            Any plan updates take effect at the end of your current billing cycle on {periodEnd}.
                        </p>
                    </div>
                    {isAnnual && (
                        <div className={`${FormStyles.upgrade12Month} margin-top--large`}>
                            <p>
                                Looking to redeem a discount on your 12 month plan? Email us at{' '}
                                <a
                                    className={`${FormStyles.upgrade12Link}`}
                                    target="blank"
                                    href="mailto:help@pvolve.com?subject=12-Month-Upgrade"
                                >
                                    help@pvolve.com
                                </a>
                                .
                            </p>
                        </div>
                    )}
                    <hr className={FormStyles.divider} />
                </>
            )
        }

        return <></>
    })

    const validateCode = async () => {
        setStatus({ errorMessage: null })
        try {
            const { promoCode } = values
            const [data] = await Pvolve.api.commerce.getDiscount([promoCode])
            setInvalidCode(false)

            if (!data) {
                setStatus({ errorMessage: 'Invalid Promo Code' })
            } else if (data.is_expired) {
                setInvalidCode(true)
                setStatus({ errorMessage: 'Promo code has expired' })
            } else if (!data.is_active) {
                setInvalidCode(true)
                setStatus({ errorMessage: 'Promo code is not active' })
            }

            setDiscount(data)
            setFieldValue('validatedPromoCode', promoCode)
        } catch (error) {
            if (typeof error === 'string') {
                setStatus({ errorMessage: error })
            } else {
                setStatus({ errorMessage: 'There was a problem adding this promo code.' })
            }
            setDiscount(undefined)
            setFieldValue('validatedPromoCode', null)
        }
    }

    const removeCode = () => {
        setFieldValue('promoCode', '')
        setFieldValue('validatedPromoCode', null)
        setStatus({ errorMessage: null })
        setDiscount(undefined)
        setInvalidCode(false)
    }

    const disabled = saving || loading

    const sourceSelectedData = useCallback(
        (selectedPlan: any): Product | undefined =>
            productGroup.products?.find(product => product.sku === selectedPlan),
        [productGroup]
    )

    const sourceSelectedDiscount = useCallback(
        (selectedPlan: any): ProductOffer | undefined =>
            productGroup.offers?.find(product => product.product.sku === selectedPlan),
        [productGroup]
    )

    const sameInterval = useCallback((productA: Product, productB: Product) => {
        if (!productA || !productB) {
            return false
        }

        const {
            subscription_details: { interval: intervalA, interval_type: intervalTypeA },
        } = productA

        const {
            subscription_details: { interval: intervalB, interval_type: intervalTypeB },
        } = productB

        return intervalA === intervalB && intervalTypeA === intervalTypeB
    }, [])

    const agreeDisclaimer = (agree = false) => {
        setRenderDisclaimer(false)
        if (agree) {
            handleSubmit()
            // START Segment Track events on Update accepted
            dispatch(Actions.segment.track.appSubscriptionUpdateConfirmationModalAccepted())
            dispatch(
                Actions.segment.track.appSubscriptionPlanUpdateSubmitted({
                    subscription_details: {
                        ...selectedPlan,
                    },
                    order_details: {
                        coupon: values.promoCode,
                        discount: subscriptionDiscountAmmount / 100,
                        subtotal: discountedBillingPrice,
                    },
                })
            )
            // END Segment Track events
        }
    }
    const setUpModal = () => {
        return (
            <Modal open={renderDisclaimer} size="tiny">
                <div className={FormStyles.modalDiv}>
                    <div className={FormStyles.modalInnerDiv}>
                        <p>
                            {isValidatedPromoCode
                                ? `${subscriptionCopy} for $${discountedBillingPrice} for the first ${subscriptionInterval} ${subscriptionIntervalType}, $${subscriptionAmount} for the following ${subscriptionInterval} ${subscriptionIntervalType}.`
                                : `${subscriptionCopy} for $${subscriptionAmount}.`}
                        </p>
                        <p>
                            Your subscription will be continuous and will automatically renew unless you cancel before
                            the end of the then-current subscription period by going to the Subscriptions page and
                            selecting "Cancel Subscription."
                        </p>

                        <p>By selecting "YES, I AGREE" you agree to the above and to the Pvolve terms of service.</p>
                        <Button onClick={() => agreeDisclaimer(true)} size="large" className={'margin-top--large'}>
                            YES, I AGREE
                        </Button>
                        <br />
                        <Button
                            onClick={() => agreeDisclaimer(false)}
                            secondary
                            size="large"
                            className={'margin-top--small'}
                        >
                            No, take me back
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }

    const pageContext = {
        theme: {
            layout: 'plain',
        },
    }

    const selectedPlan = sourceSelectedData(values.selectedPlan)
    const subscriptionInterval = selectedPlan?.subscription_details.interval
    const pluralInterval = Number(subscriptionInterval) > 1 ? `s` : ``
    const subscriptionIntervalType = `${selectedPlan?.subscription_details.interval_type}${pluralInterval}`
    const subscriptionAmount = selectedPlan?.cost.currency_amount
    const subscriptionDiscountAmmount = Math.floor(productGroup?.productDiscount(selectedPlan as Product))
    const discountedBillingPrice: ProductOffer | number | undefined =
        sourceSelectedDiscount(values.selectedPlan)?.discountedBillingPrice || subscriptionAmount
    const isValidatedPromoCode = values.validatedPromoCode
    const subscriptionCopy = `You are updating to a ${subscriptionInterval} ${subscriptionIntervalType} subscription`
    const subscriptionCancelled = subscriptionStatus === 'canceled' || subscriptionStatus === 'expiring'
    const isButtonDisabled =
        disabled ||
        !currentProduct ||
        (values.selectedPlan === currentProduct.sku && isInvalidCode) ||
        (values.promoCode != '' && !discount) ||
        isInvalidCode ||
        subscriptionCancelled

    const findCurrentProduct = () => {
        const currentOfferSelected = productGroup.offers?.find(item => item.product === currentProduct)
        !!currentOfferSelected && setCurrentOffer(currentOfferSelected)
    }

    return (
        <Layout pageContext={pageContext} location={location}>
            <div className={FormStyles.upgradePlanWrapper}>
                <SquareCloseButton />
                {setUpModal()}
                <div className={FormStyles.wrapper}>
                    <h5 className="bold upper margin-bottom--large">Plan Options</h5>
                    <Form>
                        {matchingPlan}
                        {defaultPromoDiscount && (
                            <p className="color-pv-black">
                                <Icon name="pv-checkmark-circle" /> {defaultPromoDiscount.code} promo{' '}
                                {defaultPromoDiscount.type === 'percentage' &&
                                    `(${defaultPromoDiscount.percentage}% off) `}
                                applied to below pricing
                            </p>
                        )}
                        {productGroup.offers?.map((planData, index) => {
                            {
                                /** Don't render kit shadow skus as a UI option for upgrade plan */
                            }
                            {
                                /** Don't display currently selected plan, even if it's a kit shadow sku */
                            }
                            if (
                                includes(planData.product.sku, 'kit') ||
                                replace(currentProduct?.sku, 'kit', '') === planData.product.sku ||
                                sameInterval(planData.product, currentProduct)
                            ) {
                                return null
                            }

                            const subscriptionsWithoutKitSkus = remove(webManagementProducts, p => includes(p, 'kit'))
                            const selectedProduct = find(
                                subscriptionsWithoutKitSkus,
                                p => p.sku === planData.product.sku
                            )

                            if (subscriptionsNoAvailable.includes(planData.product.sku)) {
                                return null
                            }

                            return (
                                <RadioButton
                                    setFieldValue={setFieldValue}
                                    selectedPlan={values.selectedPlan}
                                    planData={planData}
                                    promoApplied={
                                        !!(selectedProduct && discountApplies(defaultPromoDiscount, selectedProduct))
                                    }
                                    hideSavingsTag={!!defaultPromo}
                                    key={index}
                                />
                            )
                        })}
                        {!!currentOffer && (
                            <RadioButton
                                setFieldValue={setFieldValue}
                                selectedPlan={values.selectedPlan}
                                planData={currentOffer}
                                promoApplied={false}
                                hideSavingsTag={!!defaultPromo}
                            />
                        )}

                        <hr className={FormStyles.line} />

                        {!defaultPromo && (
                            <PromoInput
                                values={values}
                                handleChange={handleChange}
                                onApplyClick={validateCode}
                                onRemoveClick={removeCode}
                                appliedPromo={discount}
                                promoDescription={productGroup.discountDescription}
                                errorState={status}
                            />
                        )}

                        <Button
                            onClick={() => setRenderDisclaimer(true)}
                            disabled={isButtonDisabled}
                            size="large"
                            className="margin-top--large"
                        >
                            Save Plan
                        </Button>
                    </Form>
                </div>
            </div>
        </Layout>
    )
}

const EnhancedForm = withFormik({
    mapPropsToValues: ({ currentProduct, webManagementProducts = [] }) => ({
        promoCode: '',
        selectedPlan: currentProduct?.sku || webManagementProducts[0]?.sku,
    }),

    // Custom sync validation
    // validate: values => {
    //   const errors = {};

    //   if (!values.name) {
    //     errors.name = 'Required';
    //   }

    //   return errors;
    // },

    handleSubmit: (values, { setSubmitting, setStatus, props: { dispatch, webManagementProducts } }) => {
        setStatus({ errorMessage: null })

        let promoCode
        const selectProductObject = find(webManagementProducts, p => p.sku === values?.selectedPlan)

        if (discountApplies(values?.discount, selectProductObject)) {
            promoCode = values?.validatedPromoCode
        }

        dispatch(
            Actions.subscriptions.updatePlan.trigger({
                new_sku: values.selectedPlan,
                promo_code: promoCode,
                onFulfill: () => setSubmitting(false),
                onSuccess: () => {
                    navigate('/account/subscription', {
                        state: {
                            successMessage: 'Plan successfully updated.',
                        },
                    })
                },
                onFailure: message => {
                    setStatus({
                        errorMessage: message,
                    })
                },
            })
        )
    },

    // displayName: 'BasicForm',
})(UpgradePlanForm)

export default connector(EnhancedForm)
