import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from 'src/components/PrivateRoute'
import {
    AccountSettings,
    AccountAppSettings,
    AccountSubscription,
    CancelSubscription,
    AccountCommunications,
    UpgradePlan,
    Promo,
    StartSubscription,
    UpdateAddress,
    ChangePassword,
} from 'src/components/account'
import NotFoundPage from 'src/pages/404'

const Account = () => (
    <Router>
        <PrivateRoute path="/account/" component={AccountSettings} />
        <PrivateRoute path="/account/settings" component={AccountAppSettings} />
        <PrivateRoute path="/account/password" component={ChangePassword} />
        <PrivateRoute path="/account/subscription" component={AccountSubscription} />
        <PrivateRoute path="/account/subscription/:userId" component={AccountSubscription} />
        {/*<PrivateRoute path="/account/orders" component={AccountOrders} />*/}
        {/*<PrivateRoute path="/account/order/:orderId" component={OrderDetails} />*/}
        <PrivateRoute path="/account/communications" component={AccountCommunications} />
        <PrivateRoute path="/account/subscription/cancel" component={CancelSubscription} />
        <PrivateRoute path="/account/subscription/start" component={StartSubscription} />
        <PrivateRoute path="/account/subscription/update" component={UpgradePlan} />
        <PrivateRoute path="/account/subscription/promo" component={Promo} />
        <PrivateRoute path="/account/subscription/address" component={UpdateAddress} />
        <NotFoundPage default />
    </Router>
)

export default Account
